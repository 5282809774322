<template>
    <the-modal label="واریز تومانی" icon="deposit-modal" class="deposit-modal"
               text="جهت افزایش اعتبار کیف پول ریالی خود از طریق درگاه پرداخت، حتما باید از کارت‌های بانکی تایید شده در پروفایل استفاده نمایید."
               v-model="modal">
        <template #body>
            <section class="modal-body d-flex-c-ast-jfs-gap15 p-15">
                <the-input @input="e => data.amount = $toLocal(e)" v-model="data.amount"
                           align="right" placeholder="مبلغ واریزی" info="تومان"/>
                <the-input v-model="data.cardNumber" align="right" placeholder="شماره کارت"
                           :options="['1233 1234 1234 1234','1235 1234 4845 8774']"/>
                <the-button class="align-center" flat-light type="submit" :disabled="!data.amount || !data.cardNumber">
                    انتقال به درگاه پرداخت
                </the-button>
            </section>
        </template>
    </the-modal>
</template>

<script>
    import TheModal from "@/components/Tools/TheModal";
    import TheInput from "@/components/Tools/TheInput";
    import TheButton from "@/components/Tools/TheButton";
    import {modal} from "@/lib/reuseableFuncrtions"

    export default {
        name: "TomanDepositModal",
        components: {TheButton, TheInput, TheModal},
        props: ['show'],
        model: {
            prop: 'show',
            event: 'close'
        },
        computed:{
            modal
        },
        data() {
            return {
                data: {
                    amount: '',
                    cardNumber: ''
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .deposit-modal {
        .modal-body {
            background-color: var(--gray-box);
            border-radius: inherit;
        }
    }
</style>