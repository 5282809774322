<template>
    <the-modal :label="'برداشت ' + $coinLabel[coin]" icon="withdraw-modal" class="crypto-withdraw-modal"
               text="مبلغ موردنظر خود جهت برداشت را به شماره کارت خود انتقال دهید."
               v-model="modal">
        <template #body>
            <section class="modal-body d-flex-c-ast-jfs-gap15 p-15">
                <div class="d-flex-r-ast-jst-w-gap10">
                    <the-input @input="e => data.amount = $toLocal(e)" v-model="data.amount" align="right"
                               :placeholder="'مقدار '+$coinLabel[coin]" class="grow-1 basis-150"/>
                    <div class="info d-flex-r-ac-jsb grow-2 basis-290 s-radius">
                        <span class="d-flex-r-ac-jfs-gap10">
                            <inline-svg :src="require('@/assets/Icons/menu/wallets.svg')"/>
                            موجودی قابل برداشت
                        </span>
                        <span dir="ltr">
                            0.00043
                            {{$coinUnit[coin]}}
                        </span>
                    </div>
                </div>
                <the-input v-model="data.cardNumber" align="right" placeholder="آدرس کیف پول مقصد"/>
                <div class="info d-flex-ac-jc s-radius">
                    کارمزد انتقال 0.0002 {{$coinLabel[coin]}} بوده و مربوط به ثبت تراکنش در شبکه‌ی {{$coinLabel[coin]}} میباشد.
                </div>
                <the-button class="align-center" flat-light type="submit" :disabled="!data.amount || !data.cardNumber">
                    برداشت
                </the-button>
            </section>
        </template>
    </the-modal>
</template>

<script>
    import {modal} from "@/lib/reuseableFuncrtions";
    import TheModal from "@/components/Tools/TheModal";
    import TheInput from "@/components/Tools/TheInput";
    import TheButton from "@/components/Tools/TheButton";

    export default {
        name: "CryptoWithdrawModal",
        components: {TheButton, TheInput, TheModal},
        props: ['show', 'coin'],
        model: {
            prop: 'show',
            event: 'close'
        },
        computed: {
            modal
        },
        data() {
            return {
                data: {
                    amount: '',
                    cardNumber: ''
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.crypto-withdraw-modal{
    .info{
        background-color: var(--tooDark);
        min-height: 40px;
        padding: 0 10px;
        font-size: var(--m-font);
    }
}
</style>