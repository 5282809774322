<template>
    <transition name="side-up">
        <div class="back-drop the-modal d-flex-c-jc-ac" v-if="show" @click.self="close">
            <div class="main-drop main-modal gradient-template-modal">
                <section class="modal-content d-flex-c-ast-jfs-gap10">
                    <section class="modal-header d-flex-c-afs-jfs-gap10">
                        <h3 class="label d-flex-r-jfs-afe-gap10 l-font" :class="{'pr-50' : !icon}">
                            <img v-if="icon" height="35" :src="require('@/assets/Icons/'+icon+'.png')" alt="">
                            {{label}}
                        </h3>
                        <p v-if="text">{{text}}</p>
                    </section>
                    <slot name="body" :close="close"></slot>
                    <inline-svg @click.prevent="close" class="close-btn" width="24" :src="require('@/assets/Icons/close.svg')"/>
                </section>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "TheModal",
        props: ['show', 'label', 'icon', 'text'],
        model: {
            prop: 'show',
            event: 'close'
        },
        methods: {
            close() {
                this.$emit('close', false)
            }
        },
        mounted() {
            document.body.style.overflow = 'hidden'
        },
        beforeDestroy() {
            document.body.style.overflow = 'auto'
        }
    }
</script>

<style lang="scss" scoped>
    .the-modal {
        position: fixed;
        background-color: var(--modal-back);
        inset: 0;
        z-index: 1000;
        backdrop-filter: blur(2px);
        padding: 10px;

        .main-modal {
            width: 100%;
            max-width: 675px;
            position: relative;
            z-index: 1;
            border-radius: 9px;

            .modal-content {
                padding: 20px;
                background-color: var(--dark);
                border-radius: inherit;
                z-index: 1;
                position: relative;
                min-height: 100px;

                .close-btn {
                    position: absolute;
                    left: 20px;
                    top: 20px;
                    cursor: pointer;
                }

                .modal-header {
                    text-align: right;
                    p{
                        white-space: pre-wrap;
                    }
                }

                .modal-body{
                    border-radius: inherit;
                    background-color: var(--semiDark);
                }
            }

            &:before {
                content: '';
                position: absolute;
                inset: -4px;
                background-image: var(--linear-template);
                border-radius: inherit;
                z-index: 0;
            }
        }
    }
</style>