<template>
    <div class="wallet d-flex-c-ast-jfs">
        <h2 class="p-30">کیف پول ها</h2>
        <table>
            <tr class="header">
                <th class="gradient-template-linear-gradient-gold back">
                    <span class="d-flex-r-ac-jfs-gap20 xxl-font">
                        <img width="40" :src="require('@/assets/Icons/coins/TOMAN-DARK.svg')" alt=""/>
                        تومان
                    </span>
                </th>
                <th class="balance gradient-template-linear-gradient-gold back">
                    7,278,000
                    تومان
                </th>
                <th class="gradient-template-linear-gradient-gold back">
                    <span class="buttons d-flex-ac-jst-g1-s0-gap15">
                        <button class="deposit grow-2" @click="TomanDepositModal = true">واریز</button>
                        <button class="withdraw grow-2" @click="TomanWithdrawModal = true">برداشت</button>
                         <button class="grow-1"></button>
                    </span>
                </th>
            </tr>
            <tr class="rows" v-for="[relatedCoin,value] in Object.entries(wallets).filter(key => key[0] !=='TOMAN')"
                :key="relatedCoin">
                <td>
                    <span class="d-flex-r-ac-jfs-gap20">
                        <img width="40" :src="require('@/assets/Icons/coins/'+relatedCoin+'.png')" alt=""/>
                        {{$coinLabel[relatedCoin]}}
                    </span>
                </td>
                <td class="balance">
                    {{value.credit}}
                    {{$coinUnit[relatedCoin]}}
                </td>
                <td>
                    <span class="buttons d-flex-ac-jst-g1-s0-gap15">
                    <button class="deposit grow-2" @click="coin = relatedCoin;CryptoDepositModal = true;">واریز</button>
                    <button class="withdraw grow-2"
                            @click="coin = relatedCoin;CryptoWithdrawModal = true;">برداشت</button>
                    <a :href="$href({name : 'Market',tradeTo:'TOMAN',tradeFrom:relatedCoin})"
                       class="text-gradient gradient-template-gold-linear d-flex-r-jc-ac-gap10 grow-1">
                        معامله
                        <inline-svg :src="require('@/assets/Icons/arrow-left.svg')"/>
                    </a>
                    </span>
                </td>
            </tr>
        </table>
        <toman-withdraw-modal v-model="TomanWithdrawModal"/>
        <toman-deposit-modal v-model="TomanDepositModal"/>
        <crypto-deposit-modal v-model="CryptoDepositModal" :coin="coin"/>
        <crypto-withdraw-modal v-model="CryptoWithdrawModal" :coin="coin"/>
    </div>
</template>

<script>
    import TomanWithdrawModal from "@/components/Panel/Wallet/Modal/TomanWithdrawModal";
    import TomanDepositModal from "@/components/Panel/Wallet/Modal/TomanDepositModal";
    import CryptoDepositModal from "@/components/Panel/Wallet/Modal/CryptoDepositModal";
    import CryptoWithdrawModal from "@/components/Panel/Wallet/Modal/CryptoWithdrawModal";

    export default {
        name: "Wallet",
        components: {CryptoWithdrawModal, CryptoDepositModal, TomanDepositModal, TomanWithdrawModal},
        data() {
            return {
                TomanWithdrawModal: false,
                TomanDepositModal: false,
                CryptoDepositModal: false,
                CryptoWithdrawModal: false,
                coin: '',
                wallets: {
                    TOMAN: {
                        totalBalance: '',
                        credit: '7,278,000'
                    },
                    BITCOIN: {
                        totalBalance: '',
                        credit: '0.0008',
                        unit: 'BTC'
                    },
                    ETHEREUM: {
                        totalBalance: '',
                        credit: '123',
                        unit: 'ETH'
                    },
                    CLASSIC_ETHEREUM: {
                        totalBalance: '',
                        credit: '123',
                        unit: 'ETC'
                    },
                    DOGE_COIN: {
                        totalBalance: '',
                        credit: '123',
                        unit: 'DOGE'
                    },
                    DASH: {
                        totalBalance: '',
                        credit: '123',
                        unit: 'DASH'
                    },
                    TETHER: {
                        totalBalance: '',
                        credit: '123',
                        unit: 'USDT'
                    },
                }
            }
        }
    }
</script>

<style lang="scss">
    .wallet {
        padding: 0 20px 20px;
        text-align: right;

        table {
            border-collapse: collapse;

            tr {
                border-bottom: 1px solid var(--border);

                th {
                    height: 90px;
                    background-attachment: fixed;
                    color: var(--dark);
                    font-weight: bold;

                    &:first-of-type {
                        border-top-right-radius: var(--s-radius);
                        border-bottom-right-radius: var(--s-radius);
                    }

                    &:last-of-type {
                        border-top-left-radius: var(--s-radius);
                        border-bottom-left-radius: var(--s-radius);
                    }
                }

                td {
                    height: 75px;
                }

                &:first-of-type {
                    /*border: none;*/
                }

                &:last-of-type {
                    border: none;
                }

                td, th {
                    &:first-of-type {
                        padding-right: 20px;
                    }

                    &:last-of-type {
                        padding-left: 20px;
                    }
                }
            }

            .coin {
                span {

                }
            }

            .buttons {
                > * {
                    flex-basis: 50px;
                    height: 35px;
                }

                button {
                    border-radius: var(--s-radius);
                    font-size: var(--l-font);
                }

                .deposit {
                    background: #D1B476;
                    cursor: pointer;
                }

                .withdraw {
                    background: #3D3A34;
                    color: var(--fontcolor);
                    cursor: pointer;
                }
            }

            th .buttons {
                > * {
                    height: 40px;
                }

                .deposit {
                    background: #DA9400;
                }

                .withdraw {
                    background: #7E6A3F;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .wallet {
            table {
                font-size: 12px;
                .buttons {
                    a{
                        display: none;
                    }
                    button {
                        font-size: 12px;
                    }
                }
            }
        }
    }
</style>