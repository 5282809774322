<template>
    <the-modal :label="'واریز ' + $coinLabel[coin]" icon="deposit-modal" class="crypto-deposit-modal"
               text="برای واریز به این کیف پول، می‌توانید آدرس کیف پول را کپی کنید یا کد مقابل را اسکن کنید."
               v-model="modal">
        <template #body>
            <section class="modal-body d-flex-r-ast-jst-gap20 p-15">
                <div class="d-flex-c-ast-jsb grow-1">
                    <p class="hash">0x40beE7b4e50d756C57B4e59693905D36B9cCD1B6</p>
                    <p class="d-flex-ac-jsb">
                        <span>شبکه</span>
                        <span>{{$coinLabel[coin]}}</span>
                    </p>
                    <the-button flat-light type="button" @click="copy('0x40beE7b4e50d756C57B4e59693905D36B9cCD1B6')">
                        <inline-svg :src="require('@/assets/Icons/copy.svg')"/>
                        کپی آدرس
                    </the-button>
                </div>
                <img width="145" src="@/assets/qr.png" alt="">
            </section>
        </template>
    </the-modal>
</template>

<script>
    import TheModal from "@/components/Tools/TheModal";
    import TheButton from "@/components/Tools/TheButton";
    import {modal} from "@/lib/reuseableFuncrtions";

    export default {
        name: "CryptoDepositModal",
        components: {TheButton, TheModal},
        props: ['show', 'coin'],
        model: {
            prop: 'show',
            event: 'close'
        },
        computed: {
            modal,
        },
        data() {
            return {
                data: {
                    amount: '',
                    cardNumber: ''
                }
            }
        },
        methods: {
            copy(e) {
                console.log('aaaa')
                navigator.clipboard.writeText(e)
                alert('آدرس کپی شد')
            }
        }
    }
</script>

<style lang="scss">
    .crypto-deposit-modal {
        .modal-body {
            background-color: var(--gray-box);
            border-radius: inherit;

            .hash {
                background-color: var(--semiDark);
                padding: 10px;
                border-radius: 20px;
            }
        }
    }
</style>