<template>
    <the-modal label="برداشت تومانی" icon="withdraw-modal" class="withdraw-modal"
               text="مبلغ موردنظر خود جهت برداشت را به شماره کارت خود انتقال دهید."
               v-model="modal">
        <template #body>
            <section class="modal-body d-flex-c-ast-jfs-gap15 p-15">
                <the-input @input="e => data.amount = $toLocal(e)" v-model="data.amount" align="right"
                           placeholder="مبلغ برداشت (حداقل 50,000 تومان)" info="تومان"/>
                <the-input v-model="data.cardNumber" align="right" placeholder="شماره کارت"
                           :options="['1233 1234 1234 1234','1235 1234 4845 8774']"/>
                <the-button class="align-center" flat-light type="submit" :disabled="!data.amount || !data.cardNumber">
                    تایید برداشت
                </the-button>
            </section>
        </template>
    </the-modal>
</template>

<script>
    import TheModal from "@/components/Tools/TheModal";
    import TheInput from "@/components/Tools/TheInput";
    import TheButton from "@/components/Tools/TheButton";
    import {modal} from "@/lib/reuseableFuncrtions"

    export default {
        name: "TomanWithdrawModal",
        components: {TheButton, TheInput, TheModal},
        props: ['show'],
        model: {
            prop: 'show',
            event: 'close'
        },
        computed: {
            modal
        },
        data() {
            return {
                data: {
                    amount: '',
                    cardNumber: ''
                }
            }
        },
    }
</script>

<style lang="scss">
    .withdraw-modal {
        .modal-body {
            background-color: var(--gray-box);
            border-radius: inherit;
        }
    }
</style>